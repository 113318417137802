import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import axios from 'axios';
import './App.scss';
import {
    Redirect,
} from 'react-router-dom'

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: !!localStorage.getItem("Auth")
        };

        axios.defaults.baseURL = process.env.REACT_APP_KULONUWUN_URL;

        axios.interceptors.request.use(config => {
            let auth = localStorage.getItem("Auth");

            if (!!auth) {
                // const token = JSON.parse(localStorage.getItem("Auth"));
                // // config.headers['Authorization'] = `Bearer ${token.access_token}`;
                config.headers['X-Client-Id'] = process.env.REACT_APP_KULONUWUN_CLIENT_ID;
                config.headers['X-Client-Secret'] = process.env.REACT_APP_KULONUWUN_CLIENT_SECRET;
            }

            return config;
        }, error => {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            const originalRequest = error.config;
            if (!originalRequest._retry
                && error.response
                && error.response.status === 401
                && error.response.data.status.code === 401) {
                localStorage.removeItem("Auth");
                window.location = '/login';
            }
            return Promise.reject(error);
        });
    }

    render() {
        return (
            <HashRouter>
                <React.Suspense fallback={loading()}>
                    <Switch>
                        <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
                        <PrivateRoute path='/' render={props => <DefaultLayout {...props}/>}/>
                    </Switch>
                </React.Suspense>
            </HashRouter>
        );
    }
}

function PrivateRoute({component: Component, ...rest}) {
    let token = localStorage.getItem("Auth");
    return (
        <Route
            {...rest}
            render={(props) => !!token === true
                ? <DefaultLayout {...props} />
                : <Redirect to={{pathname: '/login', state: {from: '/'}}}/>}
        />
    )
}

export default App;
